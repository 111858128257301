import { useState, useEffect } from 'react';
import { useSupabase } from '../contexts/SupabaseContext';
import { useAuth } from './useAuth';

export const useBlogPostEditor = (initialPost, id = null) => {
  const { supabase } = useSupabase();
  const { currentUser, userRoles } = useAuth();
  const isNewPost = !id && !initialPost;

  const [post, setPost] = useState({
    title: '',
    subtitle: '',
    content: '',
    location: '',
    tldr: [],
    post_script: '',
    is_draft: true,
    subscriber_only: false,
    authors: [],
  });

  const [loading, setLoading] = useState(!isNewPost);
  const [saving, setSaving] = useState(false);
  const [saveError, setSaveError] = useState(null);
  const [preview, setPreview] = useState(false);
  const [lastSaved, setLastSaved] = useState(null);

  useEffect(() => {
    const loadPost = async () => {
      if (isNewPost) {
        setPost(prev => ({
          ...prev,
          authors: [{
            userId: currentUser.id,
            displayName: currentUser.user_metadata?.full_name || currentUser.email
          }]
        }));
        setLoading(false);
        return;
      }

      try {
        // Fetch post data
        const { data: postData, error: postError } = await supabase
          .from('blog_posts')
          .select('*')
          .eq('id', id)
          .single();

        if (postError) throw postError;

        // Check if user has permission to edit
        if (!userRoles.isAdmin && !postData.authors.includes(currentUser?.id)) {
          setSaveError('You do not have permission to edit this post');
          return;
        }

        // Fetch author profiles
        const { data: authors, error: authorsError } = await supabase
          .from('profiles')
          .select('id, first_name, last_name, display_name')
          .in('id', postData.authors);

        if (authorsError) throw authorsError;

        // Create author map
        const authorMap = new Map(authors.map(author => [
          author.id,
          {
            userId: author.id,
            displayName: author.display_name || 
                       `${author.first_name} ${author.last_name}`.trim() || 
                       'Unknown Author'
          }
        ]));

        // Transform post data
        setPost({
          ...postData,
          authors: postData.authors.map(authorId => 
              authorMap.get(authorId) || {
                userId: authorId,
                displayName: 'Unknown Author'
              }
          )
        });
        setLastSaved(new Date(postData.updated_at).toLocaleString());
      } catch (error) {
        console.error('Error fetching post:', error);
        setSaveError(error.message);
      } finally {
        setLoading(false);
      }
    };

    loadPost();
  }, [id, initialPost, isNewPost, currentUser, supabase, userRoles.isAdmin]);

  const savePost = async (isDraft = null) => {
    if (!post) return;
    
    try {
      setSaving(true);
      setSaveError(null);

      const postData = {
        title: post.title,
        subtitle: post.subtitle,
        content: post.content,
        tldr: post.tldr || [],
        post_script: post.post_script,
        location: post.location,
        is_draft: isDraft ?? post.is_draft,
        subscriber_only: post.subscriber_only,
        authors: post.authors.map(author => author.userId)
      };

      const { data, error } = isNewPost || !post.id
        ? await supabase.from('blog_posts').insert([postData]).select().single()
        : await supabase.from('blog_posts').update(postData).eq('id', post.id).select().single();

      if (error) throw error;

      setLastSaved(new Date().toLocaleString());
      return { success: true, data, isDraft: isDraft ?? post.is_draft };
    } catch (error) {
      console.error('Error saving post:', error);
      setSaveError(error.message);
      return { success: false, error };
    } finally {
      setSaving(false);
    }
  };

  const searchCoAuthors = async (query) => {
    if (!query) return [];

    try {
      const { data: authors, error } = await supabase
        .from('profiles')
        .select('id, first_name, last_name, display_name')
        .or(`display_name.ilike.%${query}%,first_name.ilike.%${query}%,last_name.ilike.%${query}%`)
        .limit(5);

      if (error) throw error;

      return authors.map(author => ({
        id: author.id,
        displayName: author.display_name || 
                   `${author.first_name} ${author.last_name}`.trim() || 
                   'Unknown Author'
      }));
    } catch (error) {
      console.error('Error searching authors:', error);
      return [];
    }
  };

  const addCoAuthor = (user) => {
    if (!post.authors.some(author => author.userId === user.id)) {
      setPost(prev => ({
        ...prev,
        authors: [...prev.authors, user]
      }));
    }
  };

  const removeAuthor = (userId) => {
    if (userId === currentUser.id) {
      setSaveError("Cannot remove yourself as an author");
      return;
    }
    setPost(prev => ({
      ...prev,
      authors: prev.authors.filter(author => author.userId !== userId)
    }));
  };

  const handleTldrAdd = (point) => {
    if (point.trim()) {
      setPost(prev => ({
        ...prev,
        tldr: [...prev.tldr, point.trim()],
      }));
    }
  };

  const handleTldrDelete = (index) => {
    setPost(prev => ({
      ...prev,
      tldr: prev.tldr.filter((_, i) => i !== index),
    }));
  };

  return {
    post,
    loading,
    saving,
    saveError,
    lastSaved,
    setPost,
    savePost,
    searchCoAuthors,
    addCoAuthor,
    removeAuthor,
    handleTldrAdd,
    handleTldrDelete,
    userRoles,
    preview,
    setPreview
  };
};
