import React, { createContext, useContext, useState, useEffect } from 'react'
import { supabase } from '../config/supabase-client'

const SupabaseContext = createContext()

export const useSupabase = () => {
  const context = useContext(SupabaseContext)
  if (!context) {
    throw new Error('useSupabase must be used within a SupabaseProvider')
  }
  return context
}

export const SupabaseProvider = ({ children }) => {
  const [isInitialized, setIsInitialized] = useState(false)

  useEffect(() => {
    const initializeSupabase = async () => {
      try {
        const { error } = await supabase.auth.getSession()
        if (error) throw error
        setIsInitialized(true)
      } catch (err) {
        console.error('Failed to initialize Supabase:', err)
      }
    }
    initializeSupabase()
  }, [])

  if (!isInitialized) {
    return null // Or a loading spinner
  }

  return (
    <SupabaseContext.Provider value={{ supabase }}>
      {children}
    </SupabaseContext.Provider>
  )
}