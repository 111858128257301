// src/index.js
// File path: src/index.js

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Render the application
/* This section creates the root element and renders the main App component within a React.StrictMode for enhanced error detection. */
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Register service worker for production
if (process.env.NODE_ENV === 'production') {
  serviceWorkerRegistration.register();
}

// Register service worker
/* This section attempts to register a service worker.  Service workers provide features like offline capabilities and push notifications.  Error handling is included to gracefully manage registration failures. */
if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(registration => {
        console.log('Service Worker registered: ', registration);
      })
      .catch(registrationError => {
        console.log('Service Worker registration failed: ', registrationError);
      });
  });
}

reportWebVitals();
