// src/services/errorHandlingService.js

import React from 'react';

// Define custom error types
export const ErrorTypes = {
  AUTH: 'auth',
  FORM: 'form',
  API: 'api',
  UNKNOWN: 'unknown',
};

// Function to get user-friendly error message
export const getUserFriendlyErrorMessage = (error, errorType = ErrorTypes.UNKNOWN) => {
  console.error('Original error:', error); // Log the original error for debugging

  switch (errorType) {
    case ErrorTypes.AUTH:
      // Handle specific Supabase auth errors
      if (error.message?.includes('Email rate limit exceeded')) {
        return 'Too many signup attempts. Please try again later.';
      }
      if (error.message?.includes('not authorized')) {
        return 'Please contact an administrator to get access to the system.';
      }
      if (error.message.includes('domain not allowed')) {
        return 'This email domain is not authorized. Please use an authorized email address (e.g., @tum.de, @gmail.com)';
      }
      return error.message || 'An authentication error occurred. Please try again.';
    case ErrorTypes.FORM:
      return error.message || 'Please check your input and try again.';
    case ErrorTypes.API:
      return 'An error occurred while communicating with the server. Please try again later.';
    default:
      return 'An unexpected error occurred. Please try again or contact support.';
  }
};

// Function to log errors (can be expanded to send logs to a server)
export const logError = (error, errorType) => {
  console.error(`Error of type ${errorType}:`, error);
  // Here you could add logic to send error logs to a server
};

// Error boundary class component

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    logError(error, ErrorTypes.UNKNOWN);
  }

  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong. Please refresh the page or contact support.</h1>;
    }

    return this.props.children;
  }
}
