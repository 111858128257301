// src/components/Header.js
// File path: src/components/Header.js

import React, { useState, useCallback, useMemo } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Button, Box, Drawer, List, ListItem, ListItemText, useMediaQuery, Menu, MenuItem } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { styled, useTheme } from '@mui/material/styles';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';
import { signOut } from '../services/supabaseService';

// Styled NavLink
/* This section styles the navigation links using MUI's styled component functionality.  It ensures that the links have a consistent appearance and behavior. */
const NavLink = styled(Button)(({ theme }) => ({
  color: '#ffffff',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
}));

const Header = ({ mode, toggleMode }) => {
  // Theme and media query
  /* This section retrieves the theme and checks if the screen size is mobile using useMediaQuery.  This is used for responsive design. */
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  // State variables
  /* This section defines the state variables for managing the mobile menu and user menu. */
  const [mobileOpen, setMobileOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  // Routing hooks
  /* This section uses React Router's useLocation and useNavigate hooks for managing navigation. */
  const location = useLocation();
  const navigate = useNavigate();
  // Authentication context
  /* This section uses the useAuth hook to access authentication information. */
  const { currentUser, userRoles } = useAuth();

  // Handle mobile drawer toggle
  /* This useCallback hook handles toggling the mobile navigation drawer.  It's used to prevent unnecessary re-renders. */
  const handleDrawerToggle = useCallback(() => {
    setMobileOpen((prevState) => !prevState);
  }, []);

  // Handle user menu open
  /* This function handles opening the user menu. */
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // Handle user menu close
  /* This function handles closing the user menu. */
  const handleClose = () => {
    setAnchorEl(null);
  };

  // Handle logout
  /* This useCallback hook handles the logout process.  It uses async/await for asynchronous operations and includes error handling. */
  const handleLogout = useCallback(async () => {
    try {
      await signOut();
      navigate('/');
      handleClose();
    } catch (error) {
      console.error('Logout error:', error);
    }
  }, [navigate]);

  // Handle profile navigation
  /* This function handles navigation to the user's profile page. */
  const handleProfile = () => {
    navigate('/profile');
    handleClose();
    if (isMobile) {
      handleDrawerToggle();
    }
  };

  // Navigation items
  /* This useMemo hook creates an array of navigation items.  This optimizes performance by only recalculating the array when necessary.  It dynamically includes the "Admin" item based on user roles. */
  const navItems = useMemo(() => [
    { title: 'Home', path: '/' },
    { title: 'About', path: '/about' },
    { title: 'Framework', path: '/framework' },
    { title: 'Blog', path: '/blog' },
    { title: 'Community', path: '/community' },
    { title: 'Learn', path: '/learn' },
    ...(userRoles.isAdmin ? [{ title: 'Admin', path: '/admin' }] : []),
  ], [userRoles.isAdmin]);

  // Mobile drawer content
  /* This section defines the content of the mobile navigation drawer. */
  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2, color: '#ffffff' }}>
        URBNIA
      </Typography>
      <List>
        {navItems.map((item) => (
          <ListItem key={item.title} disablePadding>
            <NavLink
              component={Link}
              to={item.path}
              sx={{
                width: '100%',
                justifyContent: 'center',
                color: '#ffffff',
                '&.Mui-selected': {
                  backgroundColor: 'rgba(255, 255, 255, 0.1)',
                },
              }}
              selected={location.pathname === item.path}
            >
              <ListItemText primary={item.title} />
            </NavLink>
          </ListItem>
        ))}
        {currentUser && (
          <ListItem disablePadding>
            <NavLink
              onClick={handleProfile}
              sx={{
                width: '100%',
                justifyContent: 'center',
                color: '#ffffff',
              }}
            >
              <ListItemText primary="Profile" />
            </NavLink>
          </ListItem>
        )}
        {currentUser && (
          <ListItem disablePadding>
            <NavLink
              onClick={handleLogout}
              sx={{
                width: '100%',
                justifyContent: 'center',
                color: '#ffffff',
              }}
            >
              <ListItemText primary="Logout" />
            </NavLink>
          </ListItem>
        )}
      </List>
    </Box>
  );

  // Render header
  /* This section renders the header component.  It uses MUI components for styling and layout.  The header includes the site logo, navigation links, user menu, and theme toggle.  It uses a responsive design to adapt to different screen sizes. */
  return (
    <AppBar position="static">
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        <Typography variant="h6" component={Link} to="/" sx={{ textDecoration: 'none', color: 'inherit' }}>
          URBNIA
        </Typography>

        {!isMobile && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexGrow: 1 }}>
            {navItems.map((item) => (
              <NavLink
                key={item.title}
                component={Link}
                to={item.path}
                selected={location.pathname === item.path}
              >
                {item.title}
              </NavLink>
            ))}
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircleIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {currentUser ? [
                <MenuItem key="profile" onClick={handleProfile}>Profile</MenuItem>,
                userRoles?.isAdmin && <MenuItem key="admin" component={Link} to="/admin">Admin</MenuItem>,
                <MenuItem key="logout" onClick={handleLogout}>Logout</MenuItem>
              ].filter(Boolean) : [
                <MenuItem key="login" component={Link} to="/login">Login</MenuItem>,
                <MenuItem key="register" component={Link} to="/login?tab=register">Register</MenuItem>
              ]}
            </Menu>
          </Box>
        )}

        <IconButton onClick={toggleMode} color="inherit" aria-label="Toggle dark mode">
          {mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>

        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerToggle}
            sx={{ ml: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>

      <Drawer
        variant="temporary"
        anchor="right"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', lg: 'none' },
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
            backgroundColor: '#000000',
          },
        }}
      >
        {drawer}
      </Drawer>
    </AppBar>
  );
};

export default Header;
