// src/contexts/AuthContext.js
// File path: src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { supabase } from '../config/supabase-client';

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userRoles, setUserRoles] = useState({
    isAdmin: false,
    isAuthor: false,
    isSubscriber: false
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Get initial session and handle OAuth redirect
    const initSession = async () => {
      try {
        // Get current session
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;

        // Handle any OAuth tokens in the URL
        if (window.location.hash && window.location.hash.includes('access_token')) {
          // Remove hash from URL for cleaner navigation
          window.history.replaceState(null, '', window.location.pathname);
        }

        setCurrentUser(session?.user ?? null);
        
        if (session?.user) {
          const { data: profile } = await supabase
            .from('profiles')
            .select('isadmin, isauthor, issubscriber')
            .eq('id', session.user.id)
            .single();
          
          setUserRoles({
            isAdmin: profile?.isadmin ?? false,
            isAuthor: profile?.isauthor ?? false,
            isSubscriber: profile?.issubscriber ?? false
          });
        }
      } catch (error) {
        console.error('Error initializing session:', error);
      }
      setLoading(false);
    };

    initSession();

    // Listen for auth state changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event);
        
        if (event === 'SIGNED_IN' && session?.user) {
          setCurrentUser(session.user);
          
          // Get user profile - it should already exist due to the database trigger
          const { data: profile } = await supabase
            .from('profiles')
            .select('isadmin, isauthor, issubscriber')
            .eq('id', session.user.id)
            .single();
          
          // Update profile with OAuth metadata if needed
          if (session.user.app_metadata.provider === 'google') {
            const metadata = session.user.user_metadata;
            const names = metadata.full_name?.split(' ') || [];
            const firstName = names[0] || '';
            const lastName = names.slice(1).join(' ') || '';
            
            await supabase.from('profiles').update({
              first_name: firstName,
              last_name: lastName,
              display_name: metadata.full_name || `${firstName} ${lastName}`.trim(),
              profile_pic_url: metadata.avatar_url || ''
            }).eq('id', session.user.id);
          }
          
          setUserRoles({
            isAdmin: profile?.isadmin ?? false,
            isAuthor: profile?.isauthor ?? false,
            isSubscriber: profile?.issubscriber ?? false
          });
        } else if (event === 'SIGNED_OUT') {
          setCurrentUser(null);
          setUserRoles({
            isAdmin: false,
            isAuthor: false,
            isSubscriber: false
          });
        }
      }
    );

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const value = {
    currentUser,
    userRoles,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Remove the useAuth export since it's now in its own file
