// src/config/supabase-client.js
// File path: src/config/supabase-client.js

import { createClient } from '@supabase/supabase-js'

// Supabase client configuration
/* This section configures the Supabase client using the Supabase URL and the anon key from environment variables.  This ensures that the client is properly initialized with the necessary credentials. */
const supabaseUrl = process.env.REACT_APP_SUPABASE_URL
const supabaseKey = process.env.REACT_APP_SUPABASE_ANON_KEY

if (!supabaseUrl || !supabaseKey) {
  throw new Error('Missing Supabase environment variables')
}

// Create Supabase client
/* This section creates a Supabase client instance using the configured URL and key.  This client is then exported for use throughout the application. */
export const supabase = createClient(supabaseUrl, supabaseKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
  },
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    'Pragma': 'no-cache'
  }
})

// Add helper function for getting public URLs
export const getPublicUrl = (bucket, path) => {
  if (!path) return null
  const { data } = supabase.storage
    .from(bucket)
    .getPublicUrl(path)
  return data?.publicUrl
}
