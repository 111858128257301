// src/routes/PrivateRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';  // Changed from '../contexts/AuthContext'

const PrivateRoute = ({ children, adminOnly = false }) => {
  const { currentUser, userRoles } = useAuth();

  if (!currentUser) {
    // User is not logged in, redirect to login page
    return <Navigate to="/login" replace />;
  }

  if (adminOnly && !userRoles.isAdmin) {
    // User is logged in but not an admin, redirect to home page
    return <Navigate to="/" replace />;
  }

  // User is logged in (and is an admin if required), render the protected route
  return children;
};

export default PrivateRoute;