// src/App.js
// File path: src/App.js

import React, { useState, useMemo, Suspense, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline, Box, CircularProgress } from '@mui/material';
import { createThemeWithMode } from './styles/theme';
import GlobalStyle from './styles/globalStyles';
import { supabase } from './config/supabase-client';
import { HelmetProvider } from 'react-helmet-async';

// Import necessary modules
/* This section imports all the necessary modules for the application to function correctly.  It includes React hooks, routing, styling, Supabase client, and various components and contexts. */
import Header from './components/Header';
import Footer from './components/Footer';
import AppRoutes from './routes';
import { AuthProvider } from './contexts/AuthContext';
import { SupabaseProvider } from './contexts/SupabaseContext';
import { ErrorBoundary } from './services/errorHandlingService';
import ErrorMessage from './components/ErrorMessage';

function App() {
  // Define state variables
  /* This section defines the state variables used in the application.  These variables manage the theme mode, error handling, and Supabase initialization status. */
  const [mode, setMode] = useState('light');
  const [error, setError] = useState(null);
  const [supabaseInitialized, setSupabaseInitialized] = useState(false);

  // Initialize Supabase on mount
  /* This useEffect hook initializes the Supabase client. It retrieves the user session and sets the initialization status. Error handling is included to manage potential connection issues. */
  useEffect(() => {
    const initializeSupabase = async () => {
      try {
        const { error } = await supabase.auth.getSession();
        if (error) throw error;
        setSupabaseInitialized(true);
      } catch (err) {
        setError('Failed to initialize database connection');
        console.error('Supabase initialization error:', err);
      }
    };
    initializeSupabase();
  }, []);

  // Toggle theme mode
  /* This function toggles the application's theme between light and dark mode. */
  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  // Create theme using useMemo
  /* This useMemo hook creates the theme based on the current mode. This optimizes performance by only recalculating the theme when the mode changes. */
  const theme = useMemo(() => createThemeWithMode(mode), [mode]);

  // Render loading indicator while initializing
  /* This conditional rendering displays a loading indicator while the Supabase client is initializing. */
  if (!supabaseInitialized) {
    return <CircularProgress />;
  }

  // Main application JSX
  /* This section renders the main application UI. It includes the theme provider, global styles, Supabase and Auth providers, routing, error handling, and the main application components. */
  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyle theme={theme} />
        <SupabaseProvider>
          <AuthProvider>
            <Router>
              <ErrorBoundary>
                <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                  <a href="#main-content" className="skip-link">Skip to main content</a>
                  <Header mode={mode} toggleMode={toggleMode} />
                  <Box component="main" id="main-content" sx={{ flexGrow: 1, p: { xs: 2, md: 3 } }}>
                    {error && <ErrorMessage message={error} />}
                    <Suspense fallback={<CircularProgress />}>
                      <AppRoutes setError={setError} />
                    </Suspense>
                  </Box>
                  <Footer />
                </Box>
              </ErrorBoundary>
            </Router>
          </AuthProvider>
        </SupabaseProvider>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
