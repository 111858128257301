import { supabase } from '../config/supabase-client';

// Auth functions
export const signInWithEmail = async (email, password) => {
  const { data, error } = await supabase.auth.signInWithPassword({
    email,
    password
  });
  if (error) throw error;
  return data;
};

export const signUpWithEmail = async (email, password) => {
  const { data, error } = await supabase.auth.signUp({
    email,
    password
  });
  if (error) throw error;
  
  // Initial profile will be created by database trigger
  return data;
};

export const signOut = async () => {
  const { error } = await supabase.auth.signOut();
  if (error) throw error;
};

// Storage functions
export const uploadFile = async (bucket, path, file) => {
  const { data, error } = await supabase.storage
    .from(bucket)
    .upload(path, file);
  if (error) throw error;
  return data;
};

// Profile functions
export const updateProfile = async (userId, updates) => {
  if (!userId || !updates) {
    throw new Error('Missing required parameters');
  }

  try {
    const { data, error } = await supabase
      .from('profiles')
      .update(updates)
      .eq('id', userId)
      .select()
      .single();

    if (error) throw error;
    return { data, error: null };
  } catch (error) {
    console.error('Error updating profile:', error);
    return { data: null, error };
  }
};

export const uploadProfilePicture = async (userId, file) => {
  try {
    const fileExt = file.name.split('.').pop();
    const filePath = `${userId}/profile.${fileExt}`;

    // Upload file
    const { error: uploadError } = await supabase.storage
      .from('profilePics')
      .upload(filePath, file, {
        upsert: true,
        cacheControl: '0',
      });

    if (uploadError) throw uploadError;

    // Get the public URL
    const { data } = supabase.storage
      .from('profilePics')
      .getPublicUrl(filePath);

    // Verify the file exists
    const { data: fileExists } = await supabase.storage
      .from('profilePics')
      .list(`${userId}`);

    if (!fileExists || fileExists.length === 0) {
      throw new Error('File upload failed - file not found after upload');
    }

    return data.publicUrl;
  } catch (error) {
    console.error('Error uploading profile picture:', error);
    throw error;
  }
};

export const doesFileExist = async (bucket, path) => {
  try {
    const { data, error } = await supabase.storage
      .from(bucket)
      .list(path.split('/').slice(0, -1).join('/'));

    if (error) throw error;

    return data && data.some(file => 
      file.name === path.split('/').pop()
    );
  } catch (error) {
    console.error('Error checking file existence:', error);
    return false;
  }
};

// Database functions
export const getBlogPosts = async () => {
  const { data, error } = await supabase
    .from('blog_posts')
    .select('*')
    .order('created_at', { ascending: false });
  if (error) throw error;
  return data;
};

export const getUserProfile = async (userId) => {
  const { data, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', userId)
    .single();
  if (error) throw error;
  return data;
};

//Helper function to get file URL.  This function is also used in userService.js
export const getFileUrl = (bucket, path) => {
  const { data } = supabase.storage
    .from(bucket)
    .getPublicUrl(path);
  return data.publicUrl;
};

export const getUsersWithRoles = async () => {
  try {
    // Get profiles
    const { data: profiles, error: profilesError } = await supabase
      .from('profiles')
      .select(`
        id,
        first_name,
        last_name,
        display_name,
        profile_pic_url,
        isadmin,
        isauthor,
        issubscriber
      `);
    
    if (profilesError) throw profilesError;

    // Get emails using the secure function
    const { data: emails, error: emailsError } = await supabase
      .rpc('get_user_emails');
    
    if (emailsError) throw emailsError;

    // Merge profiles with emails
    return profiles.map(profile => ({
      ...profile,
      email: emails.find(e => e.user_id === profile.id)?.email || ''
    }));

  } catch (error) {
    console.error('Error fetching users:', error);
    throw error;
  }
};
