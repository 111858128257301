// src/components/ErrorMessage.js
// File path: src/components/ErrorMessage.js

import React from 'react';
import { Alert, AlertTitle } from '@mui/material';

// ErrorMessage Component
/* This component displays an error or warning message using Material UI's Alert component.  It takes a message and an optional severity level as props. */
const ErrorMessage = ({ message, severity = 'error' }) => {
  // Render error message
  /* This section renders an alert message based on the provided message and severity.  The severity defaults to 'error' if not provided. */
  return (
    <Alert severity={severity} sx={{ mt: 2, mb: 2 }}>
      <AlertTitle>{severity === 'error' ? 'Error' : 'Warning'}</AlertTitle>
      {message}
    </Alert>
  );
};

export default ErrorMessage;
