// src/reportWebVitals.js
// File path: src/reportWebVitals.js

// Report web vitals
/* This function reports web vital metrics using the web-vitals library.  It checks if onPerfEntry is a function before proceeding, ensuring that the function is called only when necessary. */
const reportWebVitals = onPerfEntry => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    // Import and use web-vitals functions
    /* This section imports the necessary functions from the web-vitals library and calls each function with the onPerfEntry callback.  This ensures that all relevant web vital metrics are reported. */
    import('web-vitals').then(({ getCLS, getFID, getFCP, getLCP, getTTFB }) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
