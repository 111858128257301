// src/components/Footer.js
// File path: src/components/Footer.js

import React, { useState, useMemo } from 'react';
import { Box, Typography, Grid, IconButton, TextField, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GitHub, Instagram, LinkedIn, YouTube } from '@mui/icons-material';
import { FaXTwitter } from "react-icons/fa6";

// Styled Footer Link
/* This section styles the footer links using MUI's styled component functionality.  It ensures that the links have a consistent appearance and behavior. */
const FooterLink = styled('a')(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  transition: 'color 0.3s ease',
  '&:hover': {
    textDecoration: 'none',
    color: theme.palette.secondary.main,
  },
}));

const Footer = () => {
  // Newsletter subscription state
  /* This section defines the state variables for managing the newsletter subscription form.  It includes the email address, error messages, and a function to handle form submission. */
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  // Handle newsletter submission
  /* This function handles the submission of the newsletter subscription form.  It validates the email address and performs the necessary actions upon successful submission. */
  const handleSubmit = (event) => {
    event.preventDefault();
    if (!/^\S+@\S+\.\S+$/.test(email)) {
      setEmailError('Please enter a valid email address');
      return;
    }
    // Handle newsletter subscription logic here
    setEmail('');
    setEmailError('');
  };

  // Get current year
  /* This section gets the current year for display in the copyright notice. */
  const currentYear = new Date().getFullYear();

  // Social media links
  /* This useMemo hook creates an array of social media links.  This optimizes performance by only recalculating the array when necessary. */
  const socialLinks = useMemo(() => [
    { icon: <FaXTwitter />, label: 'X.com', url: 'https://x.com' },
    { icon: <LinkedIn />, label: 'LinkedIn', url: 'https://www.linkedin.com/company/urbnia' },
    { icon: <YouTube />, label: 'YouTube', url: 'https://www.youtube.com/@urbnia' },
    { icon: <GitHub />, label: 'GitHub', url: 'https://github.com/urbnia' },
    { icon: <Instagram />, label: 'Instagram', url: 'https://www.instagram.com/urbnia' },
  ], []);

  // Render footer
  /* This section renders the footer component.  It uses MUI components for styling and layout.  The footer includes sections for company information, products and services, legal information, social media links, and a newsletter subscription form. */
  return (
    <Box component="footer" sx={{ py: 4, px: { xs: 2, md: 4 }, backgroundColor: 'background.paper' }}>
      <Grid container spacing={4} justifyContent="space-evenly">
        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            URBNIA
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Ethically Advancing AI for Sustainable Urban Futures
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ mt: 2 }}>
            © {currentYear} URBNIA. All rights reserved.
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Products & Services
          </Typography>
          <FooterLink href="/coming-soon">URBNIA Indus (Pro Edition) [COMING SOON]</FooterLink>
                    <br />
          <FooterLink href="/framework">Framework</FooterLink>
          <br />
          <FooterLink href="/community">Community</FooterLink>
          <br />
          <FooterLink href="/learn">Learn</FooterLink>
          <br />
          <FooterLink href="/coming-soon">City Rankings (Coming Soon)</FooterLink>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Company
          </Typography>
          <FooterLink href="/about">About</FooterLink>
          <br />
          <FooterLink href="/contact">Contact</FooterLink>
          <br />
          <FooterLink href="/blog">Blog</FooterLink>
          <br />
          <FooterLink href="/coming-soon">News</FooterLink>
          <br />
          <FooterLink href="/coming-soon">Career</FooterLink>
        </Grid>

        <Grid item xs={12} sm={6} md={2}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Legal and Security
          </Typography>
          <FooterLink href="/coming-soon">Terms of Service</FooterLink>
          <br />
          <FooterLink href="/coming-soon">Trust and Safety</FooterLink>
          <br />
          <FooterLink href="/coming-soon">Privacy Policy (GDPR)</FooterLink>
          <br />
          <FooterLink href="/coming-soon">Cookie Notice</FooterLink>
          <br />
          <FooterLink href="/coming-soon">Imprint</FooterLink>
        </Grid>

        <Grid item xs={12} md={2}>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Social
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            {socialLinks.map((link) => (
              <IconButton 
                key={link.label}
                color="primary" 
                aria-label={`Visit URBNIA on ${link.label}`} 
                href={link.url} 
                target="_blank" 
                rel="noopener"
              >
                {link.icon}
              </IconButton>
            ))}
          </Box>
          <Typography variant="h6" color="textPrimary" gutterBottom>
            Subscribe to our Newsletter
          </Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', alignItems: 'stretch' }}>
            <TextField
              label="Email"
              variant="outlined"
              size="small"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              error={!!emailError}
              helperText={emailError}
              sx={{ mr: 1, flexGrow: 1 }}
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="small"
              sx={{ minWidth: 100 }}
            >
              Subscribe
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
